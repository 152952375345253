"use client"
import {useFormatter, useNow, useTimeZone} from "next-intl"

const FormattedNow = () => {
  const now = useNow({updateInterval: 1000 * 10})

  const formatter = useFormatter()

  const timeZone = useTimeZone()

  return formatter.dateTime(now, {
    month: "short",
    day: "numeric",
    weekday: "short",
    hour: "numeric",
    minute: "2-digit",
    timeZone,
  })
}

export default FormattedNow
